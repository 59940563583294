import { Button, Table, Modal, Select, Input } from "antd";
import React, { useEffect, useState } from "react";
import { useCompanyStore } from "../store/companyStore";
import { Link } from "react-router-dom";
import DrawerComponent from "../components/DrawerComponent";
import Swal from "sweetalert2";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useIndustryTypeStore } from "../store/industryTypeStore";
import { useSearchParams } from "react-router-dom";
import { useCityStore } from "../store/cityStore";
import { USER } from "../helpers/contants";
import _debounce from "lodash/debounce";

function Company() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { companies, fetchDataCompany, createCompany, deleteCompany } =
    useCompanyStore();
  const { allIndustryTypes, fetchAllIndustryTypes } = useIndustryTypeStore();
  const { cities, fetchCities } = useCityStore();
  const [selectedIndustryType, setSelectedIndustryType] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const sortKey = searchParams.get("sortKey");
  const sortBy = searchParams.get("sortBy");
  const city = searchParams.get("city");
  const industryType = searchParams.get("industryType");
  const [dataUser, setDataUser] = useState({});
  const { Search } = Input;
  const searchItems = searchParams.get("searchItems");

  const debouncedFetchAllCompany = _debounce((value) => {
    fetchDataCompany({ searchItems: value });
  }, 1000);

  const onSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchParams((params) => {
      params.set("searchItems", value);
      return params;
    });
    debouncedFetchAllCompany(value);
  };

  const columns = [
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      render: (value, record) =>
        `${record?.address}, ${record?.city[0]?.city_name}`,
    },
    {
      title: "Industry Type",
      dataIndex: "industry_type",
      key: "industry_type_id",
      render: (value, record) =>
        `${record?.industry_type[0]?.industry_type || "-"}`,
    },
    {
      title: "Total Logger",
      dataIndex: "total_logger",
      key: "total_logger",
      render: (value, record) => `${record?.loggerCount || "0"}`,
    },
    // {
    //   title: "Parent Company",
    //   dataIndex: "parent_company",
    //   key: "parent_company",
    // },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex gap-2 items-center">
          <Link to={`/company/${record._id}`}>
            <Button className="border-blue-500 border-1 text-blue-500 ">
              View Detail
            </Button>
          </Link>
          {["superadmin", "developer"].includes(dataUser?.role) && (
            <Button danger onClick={() => handleConfirmDelete(record._id)}>
              Delete
            </Button>
          )}
        </div>
      ),
    },
  ];

  // const sortItems = [
  //   {
  //     key: "asc",
  //     label: "Company Name: A - Z",
  //   },
  //   {
  //     key: "desc",
  //     label: "Company Name: Z - A",
  //   },
  // ];

  // const onClickSortItems = ({ key }) => {
  //   // setSelectedSortBy((prev) => ({ ...prev, sortKey: key }));
  //   setSearchParams((params) => {
  //     params.set("sortBy", "company_name");
  //     params.set("sortKey", key);
  //     return params;
  //   });
  // };

  //function for drawer

  const onClose = () => {
    setOpen(false);
  };

  //function for modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCreateCompany = async (value, actions) => {
    const d = {
      company_name: value.company_name || "",
      industry_type_id: selectedIndustryType || "",
      email: value.email || "",
      phone_number: value.phone_number || "",
      address: value.address || "",
      city_id: selectedCity || "",
    };

    createCompany(d);
    setIsModalOpen(false);

    Swal.fire({
      icon: "success",
      title: "Success!",
      text: "Success Create a Company",
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleDeleteCompany = async (id) => {
    try {
      deleteCompany(id);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Success delete company",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleConfirmDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this question",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    });
    if (result.isConfirmed) {
      handleDeleteCompany(id);
    }
  };

  const validationSchema = Yup.object().shape({
    company_name: Yup.string().required("This field is required"),
  });

  //function for industry type
  const onChange = (value, label) => {
    setSelectedIndustryType(value);
  };

  const onSearch = (value) => {};

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  //

  //function for select cities
  const onChangeCity = (value, label) => {
    setSelectedCity(value);
  };

  const onSearchCity = (value) => {};

  const filterOptionCity = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  //

  useEffect(() => {
    return () => debouncedFetchAllCompany.cancel();
  }, [debouncedFetchAllCompany]);

  useEffect(() => {
    fetchDataCompany({ sortKey, sortBy, city, industryType, searchItems });
  }, [sortKey, city, industryType, searchItems]);

  useEffect(() => {
    fetchAllIndustryTypes({ searchItems });
  }, [searchItems]);

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    setDataUser(JSON.parse(localStorage.getItem(USER)));
  }, [localStorage[USER]]);

  return (
    <div>
      <p className="text-xl font-bold">Company Data</p>

      <div className="flex mb-6 justify-between">
        {["superadmin", "developer"].includes(dataUser?.role) && (
          <div className="flex gap-2">
            <Button type="primary" onClick={showModal}>
              Create new company
            </Button>
          </div>
        )}

        <Search
          placeholder="Search company name"
          onChange={onSearchInputChange}
          style={{
            width: 220,
          }}
        />
      </div>

      <Table
        dataSource={companies}
        columns={columns}
        pagination={{ pageSize: 5 }}
        scroll={{ x: "max-content" }}
      />

      <DrawerComponent onClose={onClose} open={open} />

      <Modal
        title="Create New Company"
        open={isModalOpen}
        okText="Create"
        onCancel={handleCancel}
        footer={null}
      >
        <Formik
          initialValues={{
            company_name: "",
            industry_type_id: "",
            email: "",
            phone_number: "",
            address: "",
            city_id: "",
            parent_company: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(value, actions) => {
            handleCreateCompany(value, actions);
          }}
        >
          {(props) => {
            return (
              <div>
                <Form className="mt-8 space-y-6" action="#" method="POST">
                  <input type="hidden" name="remember" defaultValue="true" />
                  <div className="my-6">
                    <label
                      htmlFor="company-name"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Company Name
                    </label>
                    <div className="mt-2">
                      <Field
                        id="company_name"
                        name="company_name"
                        type="text"
                        required
                        className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Company Name"
                      />
                    </div>
                    <ErrorMessage
                      component="div"
                      name="company_name"
                      style={{ color: "red", fontSize: "12px" }}
                    />
                  </div>
                  <div className="my-6">
                    <label
                      htmlFor="address"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Address
                    </label>
                    <div className="mt-2">
                      <Field
                        id="address"
                        name="address"
                        type="text"
                        required
                        className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Address"
                      />
                    </div>
                  </div>

                  <div className="my-6">
                    <label
                      htmlFor="city"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      City
                    </label>
                    <Select
                      showSearch
                      id="city_id"
                      name="city_id"
                      required
                      placeholder="City"
                      optionFilterProp="children"
                      onChange={onChangeCity}
                      onSearch={onSearchCity}
                      filterOption={filterOptionCity}
                      className="w-full"
                      options={(cities || []).map((data) => ({
                        key: data._id,
                        value: data._id,
                        label: data.city_name,
                      }))}
                    />
                  </div>

                  <div className="my-6">
                    <label
                      htmlFor="industry-type"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Industry Type
                    </label>
                    <Select
                      showSearch
                      id="industry_type_id"
                      name="industry_type_id"
                      required
                      placeholder="Industry Type"
                      optionFilterProp="children"
                      onChange={onChange}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      className="w-full"
                      options={(allIndustryTypes || []).map((data) => ({
                        key: data._id,
                        value: data._id,
                        label: data.industry_type,
                      }))}
                    />
                  </div>
                  <div className="my-6">
                    <label
                      htmlFor="email"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Email Company
                    </label>
                    <div className="mt-2">
                      <Field
                        id="email"
                        name="email"
                        type="text"
                        required
                        className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Email Company"
                      />
                    </div>
                  </div>

                  <div className="my-6">
                    <label
                      htmlFor="phone_number"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Phone Number
                    </label>
                    <div className="mt-2">
                      <Field
                        id="phone_number"
                        name="phone_number"
                        type="text"
                        className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Phone Number"
                      />
                    </div>
                  </div>

                  <div className="flex justify-end gap-2 mt-4">
                    <Button key="back" onClick={handleCancel}>
                      Cancel
                    </Button>

                    <Button
                      key="submit"
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                    >
                      Create
                    </Button>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </Modal>
    </div>
  );
}

export default Company;
