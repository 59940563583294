import React from "react";
import moment from "moment";

function LatestDataComponent({ loggerDataByUID, company, logger }) {
  const convertDate = (date) => {
    const numDate = Number(date);
    return moment.unix(numDate).format("DD/MM/YYYY  HH:mm:ss");
  };

  const thresholdsData = [];
  if (logger?.thresholds_logger?._id) {
    thresholdsData.push(logger?.thresholds_logger);
  } else if (company?.id_threshold) {
    thresholdsData.push(company?.id_threshold);
  } else {
    thresholdsData.push(company?.industry_type_id?.id_threshold);
  }

  return (
    <div className="flex flex-wrap justify-center sm:justify-between gap-6">
      <div className="w-full rounded-md">
        <p className="text-center mt-6 text-[#041527] font-semibold">
          Latest Data from {convertDate(loggerDataByUID[0]?.datetime)}
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 sm:justify-between gap-6 text-center justify-center mt-4">
          <div className="bg-slate-50 border-solid border-slate-100 shadow-charts py-2 rounded-md flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              pH
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.pH >= thresholdsData[0]?.minpH &&
              loggerDataByUID[0]?.pH <= thresholdsData[0]?.maxpH ? (
                <p className="text-black m-0">{loggerDataByUID[0]?.pH}</p>
              ) : (
                <p className="text-red-600 m-0">{loggerDataByUID[0]?.pH}</p>
              )}
            </p>
          </div>
          <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              COD
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.cod <= thresholdsData[0]?.maxCod ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.cod}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.cod}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              )}
            </p>
          </div>
          <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              TSS
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.tss <= thresholdsData[0]?.maxTss ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.tss}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.tss}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              )}
            </p>
          </div>
          <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              NH3N
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.nh3n <= thresholdsData[0]?.maxNh3n ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.nh3n}
                  <sub className="text-xs text-black ml-1">mg/L</sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.nh3n}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    mg/L
                  </sub>
                </p>
              )}
            </p>
          </div>
          <div className=" bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
            <p className="text-center m-0 mb-1 font-semibold text-slate-600">
              Debit
            </p>
            <p className="font-semibold text-3xl m-0 mb-2">
              {loggerDataByUID[0]?.debit <= thresholdsData[0]?.maxDebit ? (
                <p className="text-black m-0">
                  {loggerDataByUID[0]?.debit.toFixed(4)}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    m<sup>3</sup>/menit
                  </sub>
                </p>
              ) : (
                <p className="text-red-600 m-0">
                  {loggerDataByUID[0]?.debit.toFixed(4)}
                  <sub className="text-xs font-semibold text-slate-600 ml-1">
                    m<sup>3</sup>/menit
                  </sub>
                </p>
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LatestDataComponent;
