import { Drawer, Checkbox, Button } from "antd";
import React, { useState } from "react";
import { useCompanyStore } from "../store/companyStore";
import { useSearchParams } from "react-router-dom";
import { useIndustryTypeStore } from "../store/industryTypeStore";

function DrawerComponent({ onClose, open }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const { companies } = useCompanyStore();
  const { allIndustryTypes } = useIndustryTypeStore();
  const [selectedFilter, setSelectedFilter] = useState({
    industryType: undefined,
    city: undefined,
  });

  const cities = companies.map((data) => data.city);
  const types = allIndustryTypes.map((data) => data?.industry_type);

  const removeDuplicate = (data) => {
    return [...new Set(data)];
  };

  const onChangeCity = (checkedValues) => {
    setSelectedFilter((prev) => ({ ...prev, city: checkedValues }));
  };

  const onChangeIndustryType = (checkedValues) => {
    setSelectedFilter((prev) => ({ ...prev, industryType: checkedValues }));
  };

  const onSave = () => {
    setSearchParams((params) => {
      params.set("city", selectedFilter.city);
      // params.set("industryType", selectedFilter.industryType);
      params.set("industryType", selectedFilter.industryType.join(","));
      return params;
    });

    onClose();
  };

  return (
    <Drawer title="All Filter" placement="right" onClose={onClose} open={open}>
      <div>
        <p className="text-base font-bold">Location</p>
        <Checkbox.Group
          options={removeDuplicate(cities)}
          //harusnya options didapat dari table cities
          onChange={onChangeCity}
          key={cities}
        />
      </div>

      <hr className="my-6" />

      <div>
        <p className="text-base font-bold">Industry Type</p>
        <Checkbox.Group options={types} onChange={onChangeIndustryType} />
      </div>

      <div className="my-11">
        <Button type="primary" onClick={onSave}>
          Save
        </Button>
      </div>
    </Drawer>
  );
}

export default DrawerComponent;
