import axios from "axios";
import { create } from "zustand";
import { AUTH_TOKEN } from "../helpers/contants";

export const useSensorStore = create((set, get) => ({
  sensors: [],
  sensor: {},
  fetchAllSensor: async ({ searchItems }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_BE}/sensor`, {
        params: {
          searchItems,
        },
        headers: { authorization: `Bearer ${token}` },
      });

      set((state) => ({
        data: (state.sensors = response.data.data),
      }));
    } catch (error) {}
  },
  createSensor: async (d) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BE}/sensor`,
        d,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      await get().fetchAllSensor();
    } catch (error) {}
  },
  deleteSensor: async (id) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.delete(
        `${process.env.REACT_APP_API_BE}/sensor/${id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      await get().fetchAllSensor();
    } catch (error) {}
  },
}));
