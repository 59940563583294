import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button, Checkbox } from "antd";
import { useAuthStore } from "../store/authStore";

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  // const [formResetPassword, setFormResetPassword] = useState({ email: "" });
  // const [modalResetPassword, setModalResetPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user, loginUserHandler } = useAuthStore();

  const loginSchema = Yup.object().shape({
    input: Yup.string().required("This field is required"),
    password: Yup.string()
      .required("This field is required")
      .min(8, "Password too short"),
  });

  const loginUser = async (value, actions) => {
    const data = {
      password: value.password,
      input: value.input,
    };

    setIsLoading(true);
    loginUserHandler(data);
    setIsLoading(false);
  };

  // const handleResetPasswordForm = async (event) => {
  //   // console.log(event.target.value);
  //   const key = event.target.name;
  //   setFormResetPassword({ ...formResetPassword, [key]: event.target.value });
  // };

  // const sendLinkToEmail = async (event) => {
  //   try {
  //     setIsLoading(true);
  //     event.preventDefault();

  //     let response = await axios.post(
  //       `http://localhost:9000/api/auth/reset-password-link`,
  //       formResetPassword
  //     );

  //     setIsLoading(false);
  //     setIsModalOpen(false);
  //     navigate("/login");

  //     setFormResetPassword({ email: "" });

  //     // console.log({ response });

  //     Swal.fire({
  //       icon: "success",
  //       title: "Success!",
  //       text: response.data?.message || "success",
  //     });
  //   } catch (error) {
  //     Swal.fire({
  //       icon: "error",
  //       title: "Oops...",
  //       text: error.response?.data?.message || "Something went wrong!!",
  //     });
  //     setFormResetPassword({ email: "" });
  //     setIsLoading(false);
  //     setIsModalOpen(false);
  //     navigate("/login");
  //   }
  // };

  //function for modal
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = async (data) => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSearch = (value) => {
    // console.log("search:", value);
  };
  const onChange = (value, label) => {
    // console.log("value = ", value);
    // console.log("label = ", label);
  };

  return (
    <div>
      <div>
        <Formik
          initialValues={{
            input: "",
            password: "",
          }}
          validationSchema={loginSchema}
          onSubmit={(value, actions) => {
            loginUser(value, actions);
          }}
        >
          {(props) => {
            return (
              <>
                <div className="flex w-screen min-h-screen h-full">
                  <div className="flex justify-center mx-auto flex-col p-14">
                    <div className="text-center">
                      <p className="text-3xl font-bold mb-2">
                        Spidertrac Platform
                      </p>
                      <p className="text-xl text-slate-500">
                        Log in to your account
                      </p>
                    </div>
                    <div>
                      <Form className="mt-8 space-y-6" action="#" method="POST">
                        <input
                          type="hidden"
                          name="remember"
                          defaultValue="true"
                        />
                        <div className="rounded-md">
                          <div className="my-6">
                            <label
                              htmlFor="input"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              Email or Username
                            </label>
                            <div className="mt-2">
                              <Field
                                id="input"
                                name="input"
                                type="input"
                                autoComplete="input"
                                required
                                className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                                placeholder="Email / Username"
                                // component={InputComponent}
                              />
                            </div>
                            <ErrorMessage
                              component="div"
                              name="input"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </div>

                          <div className="mt-6 mb-2">
                            <label
                              htmlFor="password"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              Password
                            </label>
                            <div className="mt-2">
                              <Field
                                id="password"
                                name="password"
                                type={show ? "text" : "password"}
                                required
                                className="pl-4 shadow-sm relative block w-[94%] h-11 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6"
                                placeholder="Password"
                                autoComplete="new-password"
                                // component={InputComponent}
                              />
                            </div>
                            <ErrorMessage
                              component="div"
                              name="password"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </div>
                        </div>
                        <Checkbox
                          style={{
                            background: "transparent",
                            color: "black",
                            marginTop: "0px",
                          }}
                          onChange={(event) => setShow(event.target.checked)}
                        >
                          <p style={{ fontSize: "14px" }}>Show Password</p>
                        </Checkbox>
                        <div>
                          <Button
                            loading={isLoading}
                            // type="submit"
                            htmlType="submit"
                            type="primary"
                            className="w-full rounded-md text-white bg-blue-500 border-none h-11 text-lg"
                          >
                            Login
                          </Button>
                          {/* <div className="flex flex-wrap gap-2 items-end justify-end my-4">
                            <p
                              onClick={() => setIsModalOpen(true)}
                              className="cursor-pointer text-color-green text-end font-bold text-base lg:text-md hover:text-cyan-900"
                            >
                              Forgot Password?
                            </p>
                          </div> */}
                        </div>
                      </Form>
                    </div>

                    <div className="flex flex-col items-center gap-1 mt-16">
                      <p className="m-0 text-slate-400">Powered By</p>
                      <p className="font-bold text-lg m-0 text-slate-600">
                        Buana Biru Teknindo
                      </p>
                    </div>
                  </div>
                </div>
              </>
            );
          }}
        </Formik>
      </div>

      {/* <Modal
        title="Forgot Password"
        open={isModalOpen}
        // onOk={handleOk}
        // okText="Create"
        onCancel={handleCancel}
        footer={null}
      >
        <p className="mb-4">
          Please input your registered email! We will send you a link to your
          email
        </p>
        <form onSubmit={sendLinkToEmail}>
          <>
            <Input
              placeholder="Enter your Name"
              // onChange={(e) => setName(e.target.value)}
              // onChange={(e) => handleResetPasswordForm(e)}
              onChange={handleResetPasswordForm}
              value={formResetPassword.email}
              id="email"
              name="email"
            />

            <div className="flex justify-end gap-2 mt-4">
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                loading={isLoading}
                // onClick={(e) => sendLinkToEmail()}
              >
                Send
              </Button>
            </div>
          </>
        </form>
      </Modal> */}
    </div>
  );
}

export default Login;
