import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AUTH_TOKEN, USER } from "../helpers/contants";
import { useAuthStore } from "../store/authStore";

function AuthRoute() {
  // const userId = JSON.parse(localStorage.getItem(USER))?.userId;
  const { user } = useAuthStore();

  //di sini per role beda masuknya
  if (localStorage.getItem(AUTH_TOKEN) && user.role === "superuser") {
    return <Navigate to="/company/logger" replace={true} />;
  } else if (localStorage.getItem(AUTH_TOKEN) && user.role === "user") {
    return <Navigate to="/user/dashboard-user" replace={true} />;
    // return <Navigate to="/user/logger-data" replace={true} />;
  } else if (localStorage.getItem(AUTH_TOKEN) && user.role === "superadmin") {
    return <Navigate to="/company" replace={true} />;
  } else if (localStorage.getItem(AUTH_TOKEN) && user.role === "developer") {
    return <Navigate to="/company" replace={true} />;
  } else if (localStorage.getItem(AUTH_TOKEN) && user.role === "internal_bbt") {
    return <Navigate to="/company" replace={true} />;
  }
  return <Outlet />;
}

export default AuthRoute;
