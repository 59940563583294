import React from "react";
import Chart from "react-apexcharts";
import moment from "moment";

function LineChartApex(props) {

  // console.log({props})
  const toFixedNumber = (value) => {
    const valueAfterMath = Math.floor(value * 100) / 100;
    return valueAfterMath
  }

  const labels = props?.chartData.map((data) => {
    return moment.unix(Number(data[props?.label])).format("HH:mm");
  });

  const series = [
    {
      name: props?.title,
      // data: props?.chartData.map((data) => data[props?.selectedKey] || 0),
      data: props?.chartData.map((data) =>
        Number.isInteger(data[props?.selectedKey]) ? data[props?.selectedKey] : toFixedNumber(data[props?.selectedKey]))
    },
  ];

  const options = {
    chart: {
      id: "line",
    },
    xaxis: {
      categories: labels,
    },
    yaxis: {
      // min: props?.min,
      // max: props?.max,
      tickAmount: 7,
    },

    annotations: {},

    title: {
      text: props?.title,
      align: "left",
      style: {
        fontSize: "14px",
        // color: "#00A9FF",
        color: props?.textColor || "#00A9FF",
      },
    },
  };

  if (props?.thresholdsValue1 && props?.thresholdsValue2) {
    options.annotations.yaxis = [
      {
        y: props?.thresholdsValue2,
        borderColor: "#E11A62",
        label: {
          borderColor: "#E11A62",
          style: {
            color: "#fff",
            background: "#E11A62",
          },
          text: `Batas Bawah: ${props?.thresholdsValue2}`,
        },
      },
      {
        y: props?.thresholdsValue1,
        borderColor: "#E11A62",
        label: {
          borderColor: "#E11A62",
          style: {
            color: "#fff",
            background: "#E11A62",
          },
          text: `Batas Atas: ${props?.thresholdsValue1}`,
        },
      },
    ];
  } else if (props?.thresholdsValue1) {
    options.annotations.yaxis = [
      {
        y: props?.thresholdsValue1,
        borderColor: "#E11A62",
        label: {
          borderColor: "#E11A62",
          style: {
            color: "#fff",
            background: "#E11A62",
          },
          text: `Batas Atas: ${props?.thresholdsValue1}`,
        },
      },
    ];
  }

  return (
    <Chart
      options={options}
      series={series}
      type="line"
      height={props?.height || 460}
    />

  );
}

export default LineChartApex;
