import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLoggerDataStore } from "../../store/loggerDataStore";
import moment from "moment";
import { Button, Table } from "antd";
import { DatePicker } from "antd";
import { useCSVDownloader } from "react-papaparse";
import * as XLSX from "xlsx";
import DropdownItems from "../../components/DropdownItems";
import ClipLoader from "react-spinners/ClipLoader";
import DownloadPdf from "../../components/DownloadPDF";

function DataPerJamComponent({ company, logger }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const { loggerDataPerHour, fetchDataPerHour } = useLoggerDataStore();
  const uidLogger = logger.uid_logger;
  const { RangePicker } = DatePicker;
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const { CSVDownloader, Type } = useCSVDownloader();
  const [loading, setLoading] = useState(false);
  loggerDataPerHour.sort((a, b) => a.datetime - b.datetime);

  const convertDate = (date) => {
    const numDate = Number(date);
    return moment.unix(numDate).format("DD/MM/YYYY  HH:mm:ss");
  };

  const downloadedDataLogger = loggerDataPerHour.map((data) => {
    return {
      logger_name: logger?.logger_name,
      date: convertDate(data?.datetimes[data?.datetimes.length - 1]),
      pH: data?.pH?.toFixed(2),
      cod: data?.cod?.toFixed(2),
      tss: data?.tss?.toFixed(2),
      nh3n: data?.nh3n?.toFixed(2),
      debit: data?.debit?.toFixed(4),
    };
  });

  const downloadXLSX = () => {
    const xlsxData = downloadedDataLogger;

    const ws = XLSX.utils.json_to_sheet(xlsxData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    XLSX.writeFile(wb, "report.xlsx");
  };

  const thresholdsData = [];
  if (company.id_threshold) {
    thresholdsData.push(company?.id_threshold);
  } else {
    thresholdsData.push(company?.industry_type_id?.id_threshold);
  }

  const columns = [
    {
      title: "Start Date",
      dataIndex: "datetime",
      key: "datetime",
      render: (value, record) =>
        convertDate(record?.datetimes[record?.datetimes.length - 1]),
    },
    {
      title: "End Date",
      dataIndex: "datetime",
      key: "datetime",
      render: (value, record) => convertDate(record?.datetimes[0]),
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">pH</p>
          </div>
        );
      },
      dataIndex: "pH",
      key: "pH",
      render: (value, record) => (record?.pH ? record?.pH?.toFixed(2) : 0),
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">TSS</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "tss",
      key: "tss",
      render: (value, record) => value?.toFixed(2) || 0,
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">COD</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "cod",
      key: "cod",
      render: (value, record) => value?.toFixed(2) || 0,
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">NH3N</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "nh3n",
      key: "nh3n",
      render: (value, record) => value?.toFixed(2) || 0,
    },
    {
      title: () => {
        return (
          <div>
            <p className="m-0">Debit</p>
            <sub>
              (m<sup>3</sup>/jam)
            </sub>
          </div>
        );
      },
      dataIndex: "debit",
      key: "debit",
      render: (value, record) => value?.toFixed(4) || 0,
    },
  ];

  const onChange = (checkedValues) => {
    if (checkedValues) {
      let startDate = checkedValues[0].$d;
      let endDate = checkedValues[1].$d;

      const date1 = moment(startDate).format("DD/MM/YYYY  HH:00:00");
      const date2 = moment(endDate).format("DD/MM/YYYY  HH:59:59");
      const dateFormat = "DD/MM/YYYY HH:mm:ss";
      const timestamp1 = moment(date1, dateFormat).unix();
      const timestamp2 = moment(date2, dateFormat).unix();

      setSearchParams((params) => {
        params.set("startDate", timestamp1);
        params.set("endDate", timestamp2);
        return params;
      });
    }
  };

  const onClear = () => {
    setSearchParams((params) => {
      params.delete("startDate");
      params.delete("endDate");
      return params;
    });
  };

  const downloadItems = [
    {
      key: "1",
      label: (
        <p className="m-0">
          <DownloadPdf data={downloadedDataLogger} />
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p className="m-0">
          <CSVDownloader
            type={Type}
            filename={"filename"}
            bom={true}
            config={{
              delimiter: ";",
            }}
            data={downloadedDataLogger}
            className="text-slate-800"
          >
            CSV
          </CSVDownloader>
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <p className="m-0" onClick={downloadXLSX}>
          XLSX
        </p>
      ),
    },
  ];

  useEffect(() => {
    if (logger?.uid_logger) {
      fetchDataPerHour({ uidLogger, startDate, endDate });
    }
  }, [uidLogger, startDate, endDate]);

  return (
    <>
      {loading ? (
        <ClipLoader
          color={"#0C356A"}
          loading={loading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <>
          <div>
            <div className="flex flex-wrap gap-2 mb-4">
              <RangePicker
                className=""
                onChange={onChange}
                allowClear={true}
                showTime={{
                  format: "HH",
                }}
                format="DD/MM/YYYY  |  HH"
              />
              <Button onClick={onClear} type="primary">
                Reset Date
              </Button>

              <DropdownItems items={downloadItems} title="Download" />
            </div>
          </div>

          <div className="mt-1">
            <Table
              dataSource={loggerDataPerHour}
              columns={columns}
              pagination={{ pageSize: 5 }}
              className="text-5xl"
              scroll={{ x: "max-content" }}
            />
          </div>
        </>
      )}
    </>
  );
}

export default DataPerJamComponent;
