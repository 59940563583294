import { Button, Table, Modal, Input, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Formik, Field, Form } from "formik";
import { useIndustryTypeStore } from "../store/industryTypeStore";
import { LineOutlined } from "@ant-design/icons";
import { useThresholdsStore } from "../store/thresholdsStore";
import { useSensorStore } from "../store/sensorStore";
import { useSearchParams } from "react-router-dom";
import _debounce from "lodash/debounce";

function IndustryType() {
  let [searchParams, setSearchParams] = useSearchParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {
    allIndustryTypes,
    fetchAllIndustryTypes,
    createIndustryType,
    deleteIndustryType,
    editIndustryType,
    fetchIndustryTypeById,
  } = useIndustryTypeStore();
  const { sensors, fetchAllSensor } = useSensorStore();
  const { editThresholdsByIdThreshold, fetchThresholdsById } =
    useThresholdsStore();
  const [formEditIndustry, setFormEditIndustry] = useState({
    industry_type: "",
    minpH: "",
    maxpH: "",
    maxCod: "",
    maxTss: "",
    maxNh3n: "",
    maxDebit: "",
    sensors: [],
  });
  const [idIndustryType, setIdIndustryType] = useState("");
  const [idThreshold, setIdThreshold] = useState("");
  const { Search } = Input;
  const searchItems = searchParams.get("searchItems");

  const debouncedFetchAllIndustryType = _debounce((value) => {
    fetchAllIndustryTypes({ searchItems: value });
  }, 1000);

  const onSearchInputChange = (e) => {
    const value = e.target.value;
    setSearchParams((params) => {
      params.set("searchItems", value);
      return params;
    });
    debouncedFetchAllIndustryType(value);
  };

  const sensorOptions = sensors.map((item) => item.sensor_name);

  const columns = [
    {
      title: "Industry Type",
      dataIndex: "industry_type",
      key: "industry_type",
      render: (value, record) => `${record?.industry_type || "-"}`,
    },
    {
      title: "Sensor",
      dataIndex: "sensors",
      key: "sensors",
      render: (value, record) =>
        record.sensors.map((d) => d.sensor_name || "-").join(", "),
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">pH</p>
          </div>
        );
      },
      dataIndex: "id_threshold",
      key: "id_threshold",
      render: (value, record) => (
        <div className="flex gap-1">
          {record?.id_threshold?.minpH || ""}
          <LineOutlined />
          {record?.id_threshold?.maxpH || ""}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">COD</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "id_threshold",
      key: "id_threshold",
      render: (value, record) => `${record?.id_threshold?.maxCod || "-"}`,
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">TSS</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "id_threshold",
      key: "id_threshold",
      render: (value, record) => `${record?.id_threshold?.maxTss || "-"}`,
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">NH3N</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "id_threshold",
      key: "id_threshold",
      render: (value, record) => `${record?.id_threshold?.maxNh3n || "-"}`,
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Debit</p>
            <sub>
              (m<sup>3</sup>/menit)
            </sub>
          </div>
        );
      },
      dataIndex: "id_threshold",
      key: "id_threshold",
      render: (value, record) => `${record?.id_threshold?.maxDebit || "-"}`,
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex gap-2 items-center">
          <Button
            onClick={() => handleEditIndustryType(record)}
            className="border-blue-500 border-1 text-blue-500 "
          >
            Edit
          </Button>

          <Button danger onClick={() => handleConfirmDelete(record._id)}>
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const handleEditIndustryType = (record) => {
    setIdIndustryType(record._id);
    setIdThreshold(record?.id_threshold?._id);
    setFormEditIndustry({
      industry_type: record?.industry_type || "-",
      minpH: record?.id_threshold?.minpH || "-",
      maxpH: record?.id_threshold?.maxpH || "-",
      maxCod: record?.id_threshold?.maxCod || "-",
      maxTss: record?.id_threshold?.maxTss || "-",
      maxNh3n: record?.id_threshold?.maxNh3n || "-",
      maxDebit: record?.id_threshold?.maxDebit || "-",
      sensors: (record?.sensors || []).map((sensor) => sensor._id),
    });
    setIsEditModalOpen(true);
  };

  const handleEditIndustryForm = async (event) => {
    const key = event.target.name;
    setFormEditIndustry({ ...formEditIndustry, [key]: event.target.value });
  };

  const handleConfirmationEditIndustry = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to edit this industry type?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Edit Industry Type",
    });
    if (result.isConfirmed) {
      onClickSubmitEditHandler();
    }
  };

  const onClickSubmitEditHandler = async () => {
    try {
      setIsLoading(true);
      editIndustryType(idIndustryType, formEditIndustry);
      editThresholdsByIdThreshold(idThreshold, formEditIndustry);
      setIsLoading(false);
      setIsEditModalOpen(false);
      setFormEditIndustry({
        industry_type: "",
        minpH: "",
        maxpH: "",
        maxCod: "",
        maxTss: "",
        maxNh3n: "",
        maxDebit: "",
        sensors: [],
      });
    } catch (error) {
      setIsLoading(false);
      setIsEditModalOpen(false);
    }
  };

  //function for modal create industry type
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCreateIndustryType = async (value, actions) => {
    if (value) {
      const d = {
        industry_type: value.industry_type || "",
        minpH: value.minpH || "",
        maxpH: value.maxpH || "",
        maxCod: value.maxCod || "",
        maxTss: value.maxTss || "",
        maxNh3n: value.maxNh3n || "",
        maxDebit: value.maxDebit || "",
        sensors: value.sensors,
      };

      createIndustryType(d);
      setIsModalOpen(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  //

  const handleCancelEditModal = () => {
    setIsEditModalOpen(false);
  };

  const handleDeleteIndustryType = async (id) => {
    try {
      deleteIndustryType(id);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Success delete industry type",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const handleConfirmDelete = async (id) => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this industry type",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    });
    if (result.isConfirmed) {
      handleDeleteIndustryType(id);
    }
  };

  // function for checkbox sensor
  const onChange = (checkedValue) => {};
  //

  // function for checkbox sensor (edit)
  const onChangeEditSensor = (checkedValues) => {
    setFormEditIndustry({ ...formEditIndustry, sensors: checkedValues });
  };
  //

  useEffect(() => {
    return () => debouncedFetchAllIndustryType.cancel();
  }, [debouncedFetchAllIndustryType]);

  useEffect(() => {
    fetchAllIndustryTypes({ searchItems });
  }, [searchItems]);

  useEffect(() => {
    if (idIndustryType) {
      fetchIndustryTypeById(idIndustryType);
    }
  }, [idIndustryType]);

  useEffect(() => {
    if (idThreshold) {
      fetchThresholdsById(idThreshold);
    }
  }, [idThreshold]);

  useEffect(() => {
    fetchAllSensor({ searchItems });
  }, [searchItems]);

  return (
    <div>
      <p className="text-xl font-bold">Industry List</p>
      <div className="flex mb-6 justify-between">
        <div className="flex gap-2">
          <Button type="primary" onClick={showModal}>
            Create new industry
          </Button>
        </div>

        <Search
          placeholder="Search industry type"
          onChange={onSearchInputChange}
          style={{
            width: 200,
          }}
        />
      </div>

      <Table
        dataSource={allIndustryTypes}
        columns={columns}
        pagination={{ pageSize: 5 }}
        scroll={{ x: "max-content" }}
      />

      <Modal
        title="Create New Industry"
        open={isModalOpen}
        // onOk={handleOk}
        okText="Create"
        onCancel={handleCancel}
        footer={null}
      >
        <Formik
          initialValues={{
            industry_type: "",
            minpH: "",
            maxpH: "",
            maxCod: "",
            maxTss: "",
            maxNh3n: "",
            maxDebit: "",
          }}
          onSubmit={(value, actions) => {
            handleCreateIndustryType(value, actions);
          }}
        >
          {(props) => {
            return (
              <div>
                <Form className="mt-8 space-y-6" action="#" method="POST">
                  <input type="hidden" name="remember" defaultValue="true" />
                  <div className="my-6">
                    <label
                      htmlFor="industry_type"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Industry Type
                    </label>
                    <div className="mt-2">
                      <Field
                        id="industry_type"
                        name="industry_type"
                        type="text"
                        required
                        className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Industry Type"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="activeSensor"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Active Sensor
                    </label>
                    <Checkbox.Group
                      // options={sensorOptions}
                      onChange={onChange}
                      options={(sensors || []).map((data) => ({
                        key: data._id,
                        value: data._id,
                        label: data.sensor_name,
                      }))}
                    />
                  </div>

                  <div className="my-6 flex gap-7 w-full">
                    <div className="w-[44%]">
                      <label
                        htmlFor="minpH"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Min pH
                      </label>
                      <div className="mt-2">
                        <Field
                          id="minpH"
                          name="minpH"
                          type="text"
                          required
                          className="pl-4 shadow-sm relative block w-full h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                          placeholder="Min pH"
                        />
                      </div>
                    </div>

                    <div className="w-[44%]">
                      <label
                        htmlFor="maxpH"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Max pH
                      </label>
                      <div className="mt-2"></div>
                      <Field
                        id="maxpH"
                        name="maxpH"
                        type="text"
                        required
                        className="pl-4 shadow-sm relative block w-full h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Max pH"
                      />
                    </div>
                  </div>

                  <div className="my-6">
                    <label
                      htmlFor="maxCod"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Max COD
                    </label>
                    <div className="mt-2">
                      <Field
                        id="maxCod"
                        name="maxCod"
                        type="text"
                        required
                        className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Max COD"
                      />
                    </div>
                  </div>

                  <div className="my-6">
                    <label
                      htmlFor="maxTss"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Max TSS
                    </label>
                    <div className="mt-2">
                      <Field
                        id="maxTss"
                        name="maxTss"
                        type="text"
                        required
                        className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Max TSS"
                      />
                    </div>
                  </div>

                  <div className="my-6">
                    <label
                      htmlFor="maxNh3n"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Max NH3N
                    </label>
                    <div className="mt-2">
                      <Field
                        id="maxNh3n"
                        name="maxNh3n"
                        type="text"
                        required
                        className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Max NH3N"
                      />
                    </div>
                  </div>

                  <div className="my-6">
                    <label
                      htmlFor="maxDebit"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Max Debit
                    </label>
                    <div className="mt-2">
                      <Field
                        id="maxDebit"
                        name="maxDebit"
                        type="text"
                        required
                        className="pl-4 shadow-sm relative block w-[94%] h-7 rounded-md border-0 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:z-10 focus:ring-2 focus:ring-inset focus:ring-blue-900 sm:text-sm sm:leading-6 "
                        placeholder="Max Debit"
                      />
                    </div>
                  </div>

                  <div className="flex justify-end gap-2 mt-4">
                    <Button key="back" onClick={handleCancel}>
                      Cancel
                    </Button>

                    <Button
                      key="submit"
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                      onClick={() => {
                        handleCreateIndustryType();
                      }}
                    >
                      Create
                    </Button>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </Modal>

      <Modal
        title="Edit Industry Type"
        open={isEditModalOpen}
        okText="Edit"
        onCancel={handleCancelEditModal}
        footer={null}
      >
        <p className="mb-1">Industry Type</p>
        <Input
          onChange={handleEditIndustryForm}
          name="industry_type"
          id="industry_type"
          value={formEditIndustry.industry_type}
        />

        <p className="mb-1">Active Sensor</p>
        <Checkbox.Group
          // options={sensorOptions}
          onChange={onChangeEditSensor}
          value={formEditIndustry.sensors}
          options={(sensors || []).map((data) => ({
            key: data._id,
            value: data._id,
            label: data.sensor_name,
          }))}
        />

        <div className="flex gap-5">
          <div className="w-[48%]">
            <p className="mb-1">Min pH</p>
            <Input
              onChange={handleEditIndustryForm}
              name="minpH"
              id="minpH"
              value={formEditIndustry.minpH}
            />
          </div>

          <div className="w-[48%]">
            <p className="mb-1">Max pH</p>
            <Input
              onChange={handleEditIndustryForm}
              name="maxpH"
              id="maxpH"
              value={formEditIndustry.maxpH}
            />
          </div>
        </div>

        <p className="mb-1">Max COD</p>
        <Input
          onChange={handleEditIndustryForm}
          name="maxCod"
          id="maxCod"
          value={formEditIndustry.maxCod}
        />

        <p className="mb-1">Max TSS</p>
        <Input
          onChange={handleEditIndustryForm}
          name="maxTss"
          id="maxTss"
          value={formEditIndustry.maxTss}
        />

        <p className="mb-1">Max NH3N</p>
        <Input
          onChange={handleEditIndustryForm}
          name="maxNh3n"
          id="maxNh3n"
          value={formEditIndustry.maxNh3n}
        />

        <p className="mb-1">Max Debit</p>
        <Input
          onChange={handleEditIndustryForm}
          name="maxDebit"
          id="maxDebit"
          value={formEditIndustry.maxDebit}
        />

        <div className="flex justify-end gap-2 mt-4">
          <Button key="back" onClick={handleCancelEditModal}>
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={isLoading}
            onClick={() => {
              handleConfirmationEditIndustry();
            }}
          >
            Edit
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default IndustryType;
