import axios from "axios";
import { create } from "zustand";
import { AUTH_TOKEN } from "../helpers/contants";
import Swal from "sweetalert2";

export const useIndustryTypeStore = create((set, get) => ({
  allIndustryTypes: [],
  industryType: {},
  createIndustryType: async (d) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BE}/industry-type`,
        d,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Success Create a industry type",
      });

      await get().fetchAllIndustryTypes();
    } catch (error) {}
  },
  fetchAllIndustryTypes: async ({ searchItems }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BE}/industry-type`,
        {
          params: {
            searchItems,
          },
          headers: { authorization: `Bearer ${token}` },
        }
      );

      set((state) => ({
        data: (state.allIndustryTypes = response.data.data),
      }));
    } catch (error) {}
  },
  fetchIndustryTypeById: async (id) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BE}/industry-type/${id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      set((state) => ({
        data: (state.industryType = response.data.data || {}),
      }));
    } catch (error) {}
  },
  deleteIndustryType: async (id) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.delete(
        `${process.env.REACT_APP_API_BE}/industry-type/${id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      await get().fetchAllIndustryTypes();
    } catch (error) {}
  },
  editIndustryType: async (id, d) => {
    const industryType = {
      industry_type: d.industry_type,
      data_sensor: d.sensors,
    };

    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BE}/industry-type/${id}`,
        industryType,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Success edit industry type",
      });

      await get().fetchAllIndustryTypes();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!!",
      });
    }
  },
}));
