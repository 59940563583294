import axios from "axios";
import { create } from "zustand";
import { AUTH_TOKEN } from "../helpers/contants";

export const useCompanyStore = create((set, get) => ({
  companies: [],
  company: {},
  createCompany: async (d) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BE}/company`,
        d,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      await get().fetchDataCompany();
    } catch (error) {}
  },
  fetchDataCompany: async ({
    sortKey,
    sortBy,
    city,
    industryType,
    searchItems,
  }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BE}/company`,
        {
          params: {
            sortBy,
            sortKey,
            city,
            industryType,
            searchItems,
          },
          headers: { authorization: `Bearer ${token}` },
        }
      );

      set((state) => ({
        data: (state.companies = response.data.data),
      }));
    } catch (error) {}
  },
  fetchCompanyById: async (id) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BE}/company/${id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      set((state) => ({
        data: (state.company = response.data.data || {}),
      }));
    } catch (error) {}
  },
  deleteCompany: async (id) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.delete(
        `${process.env.REACT_APP_API_BE}/company/${id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      await get().fetchDataCompany({});
    } catch (error) {}
  },
  editCompany: async (id, data) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BE}/company/${id}`,
        data,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      await get().fetchCompanyById(id);
    } catch (error) {}
  },
}));
