import axios from "axios";
import { create } from "zustand";
import { AUTH_TOKEN } from "../helpers/contants";

export const useLoggerDataStore = create((set, get) => ({
  allLoggerData: [],
  loggerDataByUID: [],
  loggerDataCharts: [],
  loggerDataPerMenit: [],
  loggerDataPerHour: [],
  loggerDataPerDay: [],
  fetchLoggerData: async () => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(`${process.env.REACT_APP_API_BE}/data`, {
        headers: { authorization: `Bearer ${token}` },
      });

      set((state) => ({
        data: (state.allLoggerData = response.data.data),
      }));
    } catch (error) {}
  },
  fetchLoggerDataByUid: async ({ uidLogger, startDate, endDate }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BE}/data/${uidLogger}`,
        {
          params: { startDate, endDate },
          headers: { authorization: `Bearer ${token}` },
        }
      );

      set((state) => ({
        data: (state.loggerDataByUID = response.data.data),
      }));
    } catch (error) {}
  },
  fetchLoggerDataByUidForCharts: async ({ uidLogger, startDate, endDate }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BE}/data/c/${uidLogger}`,
        {
          params: { startDate, endDate },
          headers: { authorization: `Bearer ${token}` },
        }
      );

      set((state) => ({
        data: (state.loggerDataCharts = response.data.data),
      }));
    } catch (error) {}
  },
  fetchDataPerMenit: async ({ uidLogger, startDate, endDate, page }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BE}/data/m/${uidLogger}`,
        {
          params: { startDate, endDate, page },
          headers: { authorization: `Bearer ${token}` },
        }
      );

      set((state) => ({
        data: (state.loggerDataPerMenit = response.data.data),
      }));
    } catch (error) {}
  },
  fetchDataPerHour: async ({ uidLogger, startDate, endDate, page }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BE}/data/h/${uidLogger}`,
        {
          params: { startDate, endDate, page },
          headers: { authorization: `Bearer ${token}` },
        }
      );

      set((state) => ({
        data: (state.loggerDataPerHour = response.data.data),
      }));
    } catch (error) {}
  },
  fetchDataPerDay: async ({ uidLogger, startDate, endDate, page }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BE}/data/d/${uidLogger}`,
        {
          params: { startDate, endDate, page },
          headers: { authorization: `Bearer ${token}` },
        }
      );

      set((state) => ({
        data: (state.loggerDataPerDay = response.data.data),
      }));
    } catch (error) {}
  },
}));
