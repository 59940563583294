import { Button, Dropdown } from "antd";
import React, { useState } from "react";

//tidak dipakai untuk sortby

function DropdownItems({ items, title, sortBy }) {
  const [selectedSortBy, setSelectedSortBy] = useState({
    sortBy: undefined,
    sortKey: undefined,
  });
  const onClick = ({ key }) => {
    setSelectedSortBy({ sortBy, sortKey: key });
  };

  return (
    <>
      <Dropdown
        menu={{
          items: items,
          onClick,
        }}
        placement="bottom"
      >
        <Button>{title}</Button>
      </Dropdown>
    </>
  );
}

export default DropdownItems;
