import axios from "axios";
import { create } from "zustand";
import { AUTH_TOKEN } from "../helpers/contants";
import Swal from "sweetalert2";

export const useThresholdsStore = create((set, get) => ({
  thresholds: [],
  thresholdsPerCompany: {},
  fetchThresholdsById: async (id) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_BE}/company/${id}`,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      set((state) => ({
        data: (state.thresholdsPerCompany = response.data.data),
      }));
    } catch (error) {}
  },
  createThresholdByCompanyId: async (id, d) => {
    const thresholds = {
      idCompany: id,
      maxCod: Number(d.maxCod),
      maxDebit: Number(d.maxDebit),
      maxTss: Number(d.maxTss),
      maxNh3n: Number(d.maxNh3n),
      maxpH: Number(d.maxpH),
      minpH: Number(d.minpH),
    };
    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BE}/thresholds/`,
        thresholds,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );
      // window.location.reload();
    } catch (error) {}
  },
  createThresholdByLoggerId: async (id, d) => {
    const thresholds = {
      idLogger: id.id_logger,
      maxCod: Number(d.maxCod),
      maxDebit: Number(d.maxDebit),
      maxTss: Number(d.maxTss),
      maxNh3n: Number(d.maxNh3n),
      maxpH: Number(d.maxpH),
      minpH: Number(d.minpH),
    };

    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_API_BE}/thresholds/l`,
        thresholds,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      // window.location.reload();
    } catch (error) {}
  },
  editThresholdsByIdThreshold: async (id, d) => {
    //kondisi ketika di company id thresholdnya sudah terisi, jadi edit berdasarkan idthreshold tsb

    const thresholds = {
      maxCod: Number(d.maxCod),
      maxDebit: Number(d.maxDebit),
      maxTss: Number(d.maxTss),
      maxNh3n: Number(d.maxNh3n),
      maxpH: Number(d.maxpH),
      minpH: Number(d.minpH),
    };

    const token = localStorage.getItem(AUTH_TOKEN);
    try {
      let response = await axios.put(
        `${process.env.REACT_APP_API_BE}/thresholds/i/${id}`,
        thresholds,
        {
          headers: { authorization: `Bearer ${token}` },
        }
      );

      // Swal.fire({
      //   icon: "success",
      //   title: "Success!",
      //   text: "Success edit industry type",
      // });
      // await get().fetchThresholdsById(id);
      // window.location.reload();
    } catch (error) {
      // Swal.fire({
      //   icon: "error",
      //   title: "Oops...",
      //   text: "Something went wrong!!",
      // });
    }
  },
}));
