import { Button, Table } from "antd";
import React, { useEffect } from "react";
import { useLoggerStore } from "../store/loggerStore";
import { Link } from "react-router-dom";
import { useCompanyStore } from "../store/companyStore";
import moment from "moment";
import { LineOutlined } from "@ant-design/icons";
import { USER } from "../helpers/contants";

function CompanyProfile() {
  const { fetchLoggerByCompanyId, loggersPerCompany } = useLoggerStore();
  const { company, fetchCompanyById } = useCompanyStore();
  const dataUser = JSON.parse(localStorage.getItem(USER));
  const idCompany = dataUser.company_id;

  const convertDate = (date) => {
    const numDate = Number(date);
    return moment.unix(numDate).format("DD/MM/YYYY  h:mm:ss");
  };

  const columns = [
    {
      title: "Logger Name",
      dataIndex: "logger_name",
      key: "logger_name",
      render: (value, record) => value || "-",
    },
    {
      title: "Created Date",
      dataIndex: "created_date",
      key: "created_date",
      render: (value, record) => convertDate(record.created_date),
    },
    {
      title: "pH",
      dataIndex: "ph",
      key: "ph",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.pH || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">TSS</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "tss",
      key: "tss",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.tss || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">COD</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "cod",
      key: "cod",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.cod || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">NH3N</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "nh3n",
      key: "nh3n",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.nh3n || "-",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Debit</p>
            <sub>
              (m<sup>3</sup>/menit)
            </sub>
          </div>
        );
      },
      dataIndex: "debit",
      key: "debit",
      render: (value, record) =>
        record?.log_data[record?.log_data?.length - 1]?.debit || "-",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div className="flex gap-2 items-center">
          <Link to={`/logger/${record._id}`}>
            <Button className="border-blue-500 border-1 text-blue-500 ">
              View Detail
            </Button>
          </Link>
        </div>
      ),
    },
  ];

  const columnThresholds = [
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">pH</p>
          </div>
        );
      },
      dataIndex: "pH",
      key: "pH",
      render: (value, record) => (
        <div className="flex gap-1">
          {record?.minpH || ""}
          <LineOutlined />
          {record?.maxpH || ""}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max COD</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "maxCod",
      key: "maxCod",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max TSS</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "maxTss",
      key: "maxTss",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max NH3N</p>
            <sub>(mg/L)</sub>
          </div>
        );
      },
      dataIndex: "maxNh3n",
      key: "maxNh3n",
    },
    {
      title: () => {
        return (
          <div className="">
            <p className="m-0">Max Debit</p>
            <sub>
              (m<sup>3</sup>/menit)
            </sub>
          </div>
        );
      },
      dataIndex: "maxDebit",
      key: "maxDebit",
    },
  ];

  useEffect(() => {
    fetchCompanyById(idCompany);
  }, []);

  useEffect(() => {
    fetchLoggerByCompanyId(idCompany);
  }, []);

  return (
    <div>
      <p className="text-xl font-bold">Detail of Company</p>
      <div className="w-full rounded-md mb-4 flex gap-11">
        <div className="flex justify-between pr-11 gap-4 overflow-auto">
          <div className="font-bold min-w-fit">
            <p>Company Name</p>
            <p>Industry Type</p>
            <p>Email</p>
            <p>Location</p>
          </div>

          <div className="min-w-fit">
            <p>: {company.company_name}</p>
            <p>: {company.industry_type_id?.industry_type || "-"}</p>
            <p>: {company.email ? company.email : "-"}</p>
            <p>
              : {company.address}, {company.city_id?.city_name || ""}
            </p>
          </div>
        </div>

        {/* <Button
          className="bg-green-600 text-white font-bold"
          type="text"
          onClick={showModal}
        >
          Edit Company Profile
        </Button> */}
      </div>
      <div className="mb-11">
        <p className="text-lg font-bold">Thresholds</p>
        <Table
          dataSource={
            company?.id_threshold
              ? [company?.id_threshold]
              : [company?.industry_type_id?.id_threshold]
          }
          columns={columnThresholds}
          pagination={false}
          scroll={{ x: "max-content" }}
        />
      </div>
      <hr />
      <div className="">
        <div className="text-lg font-semibold flex flex-wrap lg:flex-nowrap gap-2">
          <p>List logger of</p>
          <p className="text-blue-700">{company.company_name}</p>
        </div>
        <div>
          <Table
            dataSource={loggersPerCompany}
            columns={columns}
            pagination={{ pageSize: 5 }}
            scroll={{ x: "max-content" }}
          />
        </div>
      </div>
    </div>
  );
}

export default CompanyProfile;
