import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLoggerStore } from "../../store/loggerStore";
import { useCompanyStore } from "../../store/companyStore";
import { useLoggerDataStore } from "../../store/loggerDataStore";
import moment from "moment";
import { Button, Table } from "antd";
import { DatePicker } from "antd";
import { useCSVDownloader } from "react-papaparse";
import * as XLSX from "xlsx";
import DropdownItems from "../../components/DropdownItems";
import ClipLoader from "react-spinners/ClipLoader";
import DownloadPdf from "../../components/DownloadPDF";
import { useSensorStore } from "../../store/sensorStore";

function DataPerHari() {
  let [searchParams, setSearchParams] = useSearchParams();
  const { loggerPerUser, fetchLoggerByUserId } = useLoggerStore();
  const { company, fetchCompanyById } = useCompanyStore();
  const { loggerDataPerDay, fetchDataPerDay } = useLoggerDataStore();
  const { sensors, fetchAllSensor } = useSensorStore();
  const loggerSensor = [];
  const companyId = loggerPerUser[0]?.company_id;
  const uidLogger = loggerPerUser[0]?.uid_logger;
  const { RangePicker } = DatePicker;
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const { CSVDownloader, Type } = useCSVDownloader();
  const [loading, setLoading] = useState(false);
  loggerDataPerDay.sort((a, b) => a.datetime - b.datetime);

  // to check what sensors are active in a logger
  // if ((loggerPerUser[0]?.sensors?.length > 0) & (sensors.length !== 0)) {
  //   const dataSensor = loggerPerUser[0]?.sensors;
  //   const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

  //   loggerSensor.push(arrayOfSensors);
  // } else if (
  //   (loggerPerUser[0]?.sensors?.length === 0) &
  //   (sensors.length !== 0)
  // ) {
  //   const dataSensor = loggerPerUser[0]?.industry_type_id[0]?.sensors;
  //   const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

  //   loggerSensor.push(arrayOfSensors);
  // }

  if ((loggerPerUser[0]?.sensors?.length > 0)) {
    const dataSensor = loggerPerUser[0]?.sensors;
    const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

    loggerSensor.push(arrayOfSensors);
  } 
  else if (
    (loggerPerUser[0]?.sensors?.length)
  )
   {
    const dataSensor = loggerPerUser[0]?.industry_type_id[0]?.sensors;
    const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

    loggerSensor.push(arrayOfSensors);
  }

  const isSensorNameIncluded = (sensorName) => {
    if (loggerSensor.length > 0) {
      return loggerSensor[0].includes(sensorName);
    }
  };
  //

  const convertDate = (date) => {
    const numDate = Number(date);
    return moment.unix(numDate).format("DD/MM/YYYY");
  };

  const downloadedDataLogger = loggerDataPerDay.map((data) => {
    return {
      logger_name: loggerPerUser[0]?.logger_name,
      date: convertDate(data?.datetimes[data?.datetimes.length - 1]),
      pH: data?.pH?.toFixed(2),
      cod: data?.cod?.toFixed(2),
      tss: data?.tss?.toFixed(2),
      nh3n: data?.nh3n?.toFixed(2),
      debit: data?.debit?.toFixed(4),
    };
  });

  const downloadXLSX = () => {
    const xlsxData = downloadedDataLogger;

    const ws = XLSX.utils.json_to_sheet(xlsxData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet 1");

    XLSX.writeFile(wb, "report.xlsx");
  };

  const thresholdsData = [];
  if (company.id_threshold) {
    thresholdsData.push(company?.id_threshold);
  } else {
    thresholdsData.push(company?.industry_type_id?.id_threshold);
  }

  const columns = [
    {
      title: "Date",
      dataIndex: "datetime",
      key: "datetime",
      render: (value, record) =>
        convertDate(record?.datetimes[record?.datetimes.length - 1]),
    },
    isSensorNameIncluded("pH")
      ? {
          title: () => {
            return (
              <div>
                <p className="m-0">pH</p>
              </div>
            );
          },
          dataIndex: "pH",
          key: "pH",
          render: (value, record) => (record?.pH ? record?.pH?.toFixed(2) : 0),
        }
      : {},
    isSensorNameIncluded("tss")
      ? {
          title: () => {
            return (
              <div>
                <p className="m-0">TSS</p>
                <sub>(mg/L)</sub>
              </div>
            );
          },
          dataIndex: "tss",
          key: "tss",
          render: (value, record) => value?.toFixed(2) || 0,
        }
      : {},
    isSensorNameIncluded("cod")
      ? {
          title: () => {
            return (
              <div>
                <p className="m-0">COD</p>
                <sub>(mg/L)</sub>
              </div>
            );
          },
          dataIndex: "cod",
          key: "cod",
          render: (value, record) => value?.toFixed(2) || 0,
        }
      : {},
    isSensorNameIncluded("nh3n")
      ? {
          title: () => {
            return (
              <div>
                <p className="m-0">NH3N</p>
                <sub>(mg/L)</sub>
              </div>
            );
          },
          dataIndex: "nh3n",
          key: "nh3n",
          render: (value, record) => value?.toFixed(2) || 0,
        }
      : {},
    isSensorNameIncluded("debit")
      ? {
          title: () => {
            return (
              <div>
                <p className="m-0">Debit</p>
                <sub>
                  (m<sup>3</sup>/hari)
                </sub>
              </div>
            );
          },
          dataIndex: "debit",
          key: "debit",
          render: (value, record) => value?.toFixed(4) || 0,
        }
      : {},
  ];

  const onChange = (checkedValues) => {
    if (checkedValues) {
      let startDate = checkedValues[0].$d;
      let endDate = checkedValues[1].$d;

      const date1 = moment(startDate).format("DD/MM/YYYY  00:0:0");
      const date2 = moment(endDate).format("DD/MM/YYYY  23:59:59");
      // const date1 = moment(startDate).format("DD/MM/YYYY  HH:mm:ss");
      // const date2 = moment(endDate).format("DD/MM/YYYY  HH:mm:ss");
      const dateFormat = "DD/MM/YYYY HH:mm:ss";
      const timestamp1 = moment(date1, dateFormat).unix();
      const timestamp2 = moment(date2, dateFormat).unix();

      setSearchParams((params) => {
        params.set("startDate", timestamp1);
        params.set("endDate", timestamp2);
        return params;
      });
    }
  };

  const onClear = () => {
    setSearchParams((params) => {
      params.delete("startDate");
      params.delete("endDate");
      return params;
    });
  };

  const downloadItems = [
    {
      key: "1",
      label: (
        <p className="m-0">
          <DownloadPdf data={downloadedDataLogger} />
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p className="m-0">
          <CSVDownloader
            type={Type}
            filename={"filename"}
            bom={true}
            config={{
              delimiter: ";",
            }}
            data={downloadedDataLogger}
            className="text-slate-800"
          >
            CSV
          </CSVDownloader>
        </p>
      ),
    },
    {
      key: "3",
      label: (
        <p className="m-0" onClick={downloadXLSX}>
          XLSX
        </p>
      ),
    },
  ];

  useEffect(() => {
    if (loggerPerUser) {
      fetchLoggerByUserId();
    }
  }, [loggerPerUser._id]);

  useEffect(() => {
    if (companyId) {
      fetchCompanyById(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (loggerPerUser[0]?.uid_logger) {
      fetchDataPerDay({ uidLogger, startDate, endDate });
    }
  }, [uidLogger, startDate, endDate]);

  return (
    <>
      {loading ? (
        <ClipLoader
          color={"#0C356A"}
          loading={loading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <>
          <p className="text-xl font-bold text-center m-0">
            {loggerPerUser[0]?.logger_name || "-"}
          </p>
          <p className="text-center text-slate-400 ">
            {loggerPerUser[0]?.logger_address || ""}
          </p>

          <div>
            <p className="font-semibold sm:text-lg mt-11 text-slate-500">
              Data Logger Per Hari
            </p>
            <div className="flex flex-wrap gap-2">
              <RangePicker
                className="mb-4 "
                onChange={onChange}
                allowClear={true}
                // showTime={{
                //   format: "HH:mm",
                // }}
                format="DD/MM/YYYY"
              />
              <Button onClick={onClear} type="primary">
                Reset Date
              </Button>

              <DropdownItems items={downloadItems} title="Download" />
            </div>
          </div>

          <div className="mt-6">
            <Table
              dataSource={loggerDataPerDay}
              columns={columns}
              // pagination={{ pageSize: 20 }}
              className="text-5xl"
              scroll={{ x: "max-content" }}
            />
          </div>
        </>
      )}
    </>
  );
}

export default DataPerHari;
