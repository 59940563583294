import React, { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useLoggerStore } from "../../store/loggerStore";
import { useCompanyStore } from "../../store/companyStore";
import { useLoggerDataStore } from "../../store/loggerDataStore";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { Progress } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import LineChartApex from "../../components/LineChartApex";
import { useSensorStore } from "../../store/sensorStore";
import { Spin } from 'antd'

function DisplayPage() {
  const { id } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const { loggerPerUser, fetchLoggerByUserId } = useLoggerStore();
  const { company, fetchCompanyById } = useCompanyStore();
  // const { loggerDataPerMenit, fetchDataPerMenit } = useLoggerDataStore();
  const {
    loggerDataByUID,
    loggerDataCharts,
    fetchLoggerDataByUidForCharts,
    fetchLoggerDataByUid,
  } = useLoggerDataStore();
  const { sensors, fetchAllSensor } = useSensorStore();
  const loggerSensor = [];
  const companyId = loggerPerUser[0]?.company_id || "";
  const uidLogger = loggerPerUser[0]?.uid_logger || "";
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const [loading, setLoading] = useState(true);
  const searchItems = searchParams.get("searchItems");


  const slicedData = loggerDataCharts.slice(0, 20);
  slicedData.sort((a, b) => a.datetime - b.datetime);

  const toFixedNumber = (value, decimals) => {
    if (decimals === 2) {
      const valueAfterMath = Math.floor(value * 100) / 100;
      return valueAfterMath
    } else {
      const valueAfterMath = Math.floor(value * 10000) / 10000;
      return valueAfterMath

    }
  }



  // to check what sensors are active in a logger
  // if ((loggerPerUser[0]?.sensors?.length > 0) && (sensors.length !== 0)) {
  //   const dataSensor = loggerPerUser[0]?.sensors;
  //   const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

  //   loggerSensor.push(arrayOfSensors);
  // } 
  // else if (
  //   (loggerPerUser[0]?.sensors?.length === 0) &&
  //   (sensors.length !== 0)
  // )
  //  {
  //   const dataSensor = loggerPerUser[0]?.industry_type_id[0]?.sensors;
  //   const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

  //   loggerSensor.push(arrayOfSensors);
  // }

  if ((loggerPerUser[0]?.sensors?.length > 0)) {
    const dataSensor = loggerPerUser[0]?.sensors;
    const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

    loggerSensor.push(arrayOfSensors);
  }
  else if (
    (loggerPerUser[0]?.sensors?.length)
  ) {
    const dataSensor = loggerPerUser[0]?.industry_type_id[0]?.sensors;
    const arrayOfSensors = dataSensor.map((s) => s.sensor_name);

    loggerSensor.push(arrayOfSensors);
  }


  const isSensorNameIncluded = (sensorName) => {
    if (loggerSensor.length > 0) {
      return loggerSensor[0].includes(sensorName);
    }
  };
  //

  const percentageData = () => {
    // const get24Time = moment().subtract(1, "days").unix();
    const get24Time = moment()
      .subtract(1, "days")
      .subtract(2, "minutes")
      .unix();

    const filteredData = loggerDataByUID.filter(
      (data) => data.datetime >= get24Time
    );

    const percentage = (filteredData.length / 720) * 100;

    return Math.min(100, percentage).toFixed(1);
  };

  const initialMinMax = {
    cod: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    debit: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    nh3n: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    pH: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    tss: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
  };

  if (slicedData.length > 0) {
    slicedData.forEach((item) => {
      Object.keys(initialMinMax).forEach((property) => {
        initialMinMax[property].min = Math.min(
          initialMinMax[property].min,
          item[property]
        );
        initialMinMax[property].max = Math.max(
          initialMinMax[property].max,
          item[property]
        );
      });
    });
  } else {
    // Set initialMinMax values to 0 when loggerDataCharts is empty
    Object.keys(initialMinMax).forEach((property) => {
      initialMinMax[property].min = 0;
      initialMinMax[property].max = 0;
    });
  }

  const convertDate = (date) => {
    const numDate = Number(date);
    return moment.unix(numDate).format("DD/MM/YYYY  HH:mm:ss");
  };

  const thresholdsData = [];
  if (loggerPerUser.length > 0 && loggerPerUser[0]?.thresholds_logger[0]?._id) {
    thresholdsData.push(loggerPerUser[0]?.thresholds_logger[0]);
  } else if (company?.id_threshold) {
    thresholdsData.push(company?.id_threshold);
  } else {
    thresholdsData.push(company?.industry_type_id?.id_threshold);
  }

  useEffect(() => {
    fetchAllSensor({ searchItems });
  }, [searchItems]);

  useEffect(() => {
    if (loggerPerUser) {
      fetchLoggerByUserId();
    }
  }, [loggerPerUser._id]);

  useEffect(() => {
    if (companyId) {
      fetchCompanyById(companyId);
    }
  }, [companyId]);

  //fetching data first time
  // useEffect(() => {
  //   if (loggerPerUser.length > 0 && loggerPerUser[0]?.uid_logger) {
  //     fetchLoggerDataByUid({ uidLogger, startDate, endDate });
  //   }
  // }, [uidLogger]);

  useEffect(() => {
    if (loggerPerUser.length > 0 && loggerPerUser[0]?.uid_logger) {

      const timerId = setTimeout(() => {
        fetchLoggerDataByUid({ uidLogger, startDate, endDate });
        setLoading(false);
      }, 2000);
      return () => clearTimeout(timerId);
    }
  }, [uidLogger]);

  //fetching data every 2 minutes
  useEffect(() => {
    if (loggerPerUser.length > 0 && loggerPerUser[0]?.uid_logger) {
      const interval = setInterval(() => {
        fetchLoggerDataByUid({ uidLogger, startDate, endDate });
      }, 120000);
      return () => clearInterval(interval);
    }
  }, [uidLogger, startDate, endDate]);

  //fetching data first time
  useEffect(() => {
    if (loggerPerUser.length > 0 && loggerPerUser[0]?.uid_logger) {
      const timerId = setTimeout(() => {
        fetchLoggerDataByUidForCharts({ uidLogger, startDate, endDate });
        setLoading(false);
      }, 2000);
      return () => clearTimeout(timerId);
    }

  }, [uidLogger]);

  // //fetching data every 2 minutes
  useEffect(() => {
    if (loggerPerUser[0]?.uid_logger) {
      const interval = setInterval(() => {
        fetchLoggerDataByUidForCharts({ uidLogger, startDate, endDate });
      }, 120000);
      return () => clearInterval(interval);
    }
  }, [uidLogger, startDate, endDate]);


  return (

    <>
      {loading ? (
        <div className="fixed inset-0 flex flex-col items-center justify-center">
          <Spin className="" size="large" />
          <p className="text-blue-400">Loading...</p>

        </div>
      ) : (
        <>


          <p className="text-xl font-bold text-center m-0">
            {loggerPerUser[0]?.logger_name || "-"}
          </p>

          {/* h-full sm:h-[calc(100vh-220px)] */}

          <p className="text-center mt-2 text-slate-600 m-0">
            Latest Data from {convertDate(loggerDataByUID[0]?.datetime)}
          </p>

          <div className="w-full rounded-md">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 sm:justify-between gap-6 text-center justify-center mt-4">
              {isSensorNameIncluded("pH") ? (
                <div className="bg-slate-50 border-solid border-slate-100 shadow-charts py-2 rounded-md flex flex-col items-center justify-center">
                  <p className="text-center m-0 mb-1 font-semibold text-slate-600">
                    pH
                  </p>
                  <p className="font-semibold text-3xl m-0 mb-2">
                    {loggerDataByUID[0]?.pH >= thresholdsData[0]?.minpH &&
                      loggerDataByUID[0]?.pH <= thresholdsData[0]?.maxpH ? (
                      <p className="text-black m-0">
                        {Number.isInteger(loggerDataByUID[0]?.pH) ? loggerDataByUID[0]?.pH : toFixedNumber(loggerDataByUID[0]?.pH, 2)}
                      </p>
                    ) : (
                      <p className="text-red-600 m-0">
                        {Number.isInteger(loggerDataByUID[0]?.pH) ? loggerDataByUID[0]?.pH : toFixedNumber(loggerDataByUID[0]?.pH, 2)}
                      </p>
                    )}
                  </p>
                </div>
              ) : (
                ""
              )}

              {isSensorNameIncluded("cod") ? (
                <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
                  <p className="text-center m-0 mb-1 font-semibold text-slate-600">
                    COD
                  </p>
                  <p className="font-semibold text-3xl m-0 mb-2">
                    {loggerDataByUID[0]?.cod <= thresholdsData[0]?.maxCod ? (
                      <p className="text-black m-0">
                        {Number.isInteger(loggerDataByUID[0]?.cod) ? loggerDataByUID[0]?.cod : toFixedNumber(loggerDataByUID[0]?.cod, 2)}
                        <sub className="text-xs font-semibold text-slate-600 ml-1">
                          mg/L
                        </sub>
                      </p>
                    ) : (
                      <p className="text-red-600 m-0">
                        {Number.isInteger(loggerDataByUID[0]?.cod) ? loggerDataByUID[0]?.cod : toFixedNumber(loggerDataByUID[0]?.cod, 2)}
                        <sub className="text-xs font-semibold text-slate-600 ml-1">
                          mg/L
                        </sub>
                      </p>
                    )}
                  </p>
                </div>
              ) : (
                ""
              )}

              {isSensorNameIncluded("tss") ? (
                <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
                  <p className="text-center m-0 mb-1 font-semibold text-slate-600">
                    TSS
                  </p>
                  <p className="font-semibold text-3xl m-0 mb-2">
                    {loggerDataByUID[0]?.tss <= thresholdsData[0]?.maxTss ? (
                      <p className="text-black m-0">
                        {Number.isInteger(loggerDataByUID[0]?.tss) ? loggerDataByUID[0]?.tss : toFixedNumber(loggerDataByUID[0]?.tss, 2)}
                        <sub className="text-xs font-semibold text-slate-600 ml-1">
                          mg/L
                        </sub>
                      </p>
                    ) : (
                      <p className="text-red-600 m-0">
                        {Number.isInteger(loggerDataByUID[0]?.tss) ? loggerDataByUID[0]?.tss : toFixedNumber(loggerDataByUID[0]?.tss, 2)}
                        <sub className="text-xs font-semibold text-slate-600 ml-1">
                          mg/L
                        </sub>
                      </p>
                    )}
                  </p>
                </div>
              ) : (
                ""
              )}

              {isSensorNameIncluded("nh3n") ? (
                <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
                  <p className="text-center m-0 mb-1 font-semibold text-slate-600">
                    NH3N
                  </p>
                  <p className="font-semibold text-3xl m-0 mb-2">
                    {loggerDataByUID[0]?.nh3n <= thresholdsData[0]?.maxNh3n ? (
                      <p className="text-black m-0">
                        {Number.isInteger(loggerDataByUID[0]?.nh3n) ? loggerDataByUID[0]?.nh3n : toFixedNumber(loggerDataByUID[0]?.nh3n, 2)}
                        <sub className="text-xs text-black ml-1">mg/L</sub>
                      </p>
                    ) : (
                      <p className="text-red-600 m-0">
                        {Number.isInteger(loggerDataByUID[0]?.nh3n) ? loggerDataByUID[0]?.nh3n : toFixedNumber(loggerDataByUID[0]?.nh3n, 2)}
                        <sub className="text-xs font-semibold text-slate-600 ml-1">
                          mg/L
                        </sub>
                      </p>
                    )}
                  </p>
                </div>
              ) : (
                ""
              )}

              {isSensorNameIncluded("debit") ? (
                <div className=" bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts py-2 flex flex-col items-center justify-center">
                  <p className="text-center m-0 mb-1 font-semibold text-slate-600">
                    Debit
                  </p>
                  <p className="font-semibold text-3xl m-0 mb-2">
                    {loggerDataByUID[0]?.debit <=
                      thresholdsData[0]?.maxDebit ? (
                      <p className="text-black m-0">
                        {Number.isInteger(loggerDataByUID[0]?.debit) ? loggerDataByUID[0]?.debit : toFixedNumber(loggerDataByUID[0]?.debit, 4)}
                        <sub className="text-xs font-semibold text-slate-600 ml-1">
                          m<sup>3</sup>/menit
                        </sub>
                      </p>
                    ) : (
                      <p className="text-red-600 m-0">
                        {Number.isInteger(loggerDataByUID[0]?.debit) ? loggerDataByUID[0]?.debit : toFixedNumber(loggerDataByUID[0]?.debit, 4)}
                        <sub className="text-xs font-semibold text-slate-600 ml-1">
                          m<sup>3</sup>/menit
                        </sub>
                      </p>
                    )}
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {/* w-full md:w-[40%] h-[calc(100vh-220px)] */}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-6 gap-6">
            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <div className=" flex items-center justify-center gap-4 w-1/2 mx-auto py-6 rounded-md">
                <Progress
                  type="circle"
                  percent={percentageData()}
                // strokeWidth={8}
                // strokeColor="#ffffff"
                // trailWidth={8}
                // trailColor="#e0e0e0"
                />
                {percentageData() === 100 ? (
                  <p className="font-bold text-4xl m-0 mb-2 text-center text-white">
                    {percentageData()}%
                  </p>
                ) : (
                  ""
                )}
              </div>
              <p className="text-center m-0 mb-4 text-[#041527] italic">
                * Pemenuhan data dalam 24 jam
              </p>
            </div>
            {isSensorNameIncluded("pH") ? (
              <div className="bg-slate-50 border-solid border-slate-100 p-1 rounded-md shadow-charts overflow-scroll hide-scrollbar">
                <LineChartApex
                  title="pH"
                  selectedKey="pH"
                  label="datetime"
                  chartData={slicedData || []}
                  thresholdsValue1={thresholdsData[0]?.maxpH || []}
                  thresholdsValue2={thresholdsData[0]?.minpH || []}
                  // min={
                  //   initialMinMax?.pH?.min <= 2
                  //     ? initialMinMax?.pH?.min
                  //     : initialMinMax?.pH?.min - 2
                  // }
                  // max={
                  //   initialMinMax?.pH?.max >= 14
                  //     ? initialMinMax?.pH?.max
                  //     : initialMinMax?.pH?.max + 1
                  // }
                  height={186}
                  textColor="#041527"
                />
                <div className="flex justify-between pt-2">
                  <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.minpH} - {thresholdsData[0]?.maxpH}</p>
                  <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                  <p className="m-2 -mt-8 text-xs text-slate-500">
                    Pemenuhan data pH dalam 24 jam: {percentageData()}%
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            {isSensorNameIncluded("cod") ? (
              <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts overflow-scroll hide-scrollbar">
                <LineChartApex
                  title="COD"
                  selectedKey="cod"
                  label="datetime"
                  chartData={slicedData || []}
                  thresholdsValue1={thresholdsData[0]?.maxCod || []}
                  // min={initialMinMax?.cod?.min}
                  // max={initialMinMax?.cod?.max}
                  height={186}
                  textColor="#041527"
                />
                <div className="flex justify-between pt-2">
                  <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxCod}</p>
                  <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                  <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                    Pemenuhan data COD dalam 24 jam: {percentageData()}%
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("tss") ? (
              <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts overflow-scroll hide-scrollbar">
                <LineChartApex
                  title="TSS"
                  selectedKey="tss"
                  label="datetime"
                  chartData={slicedData || []}
                  thresholdsValue1={thresholdsData[0]?.maxTss || []}
                  // min={initialMinMax?.tss?.min}
                  // max={initialMinMax?.tss?.max}
                  height={186}
                  textColor="#041527"
                />
                <div className="flex justify-between pt-2">
                  <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxTss}</p>
                  <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                  <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                    Pemenuhan data TSS dalam 24 jam: {percentageData()}%
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("nh3n") ? (
              <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts overflow-scroll hide-scrollbar">
                <LineChartApex
                  title="NH3N"
                  selectedKey="nh3n"
                  label="datetime"
                  chartData={slicedData || []}
                  thresholdsValue1={thresholdsData[0]?.maxNh3n || []}
                  // min={initialMinMax?.nh3n?.min}
                  // max={initialMinMax?.nh3n?.max}
                  height={186}
                  textColor="#041527"
                />
                <div className="flex justify-between pt-2">
                  <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxNh3n}</p>
                  <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                  <p className="m-2 -mt-8 text-right text-xs text-slate-500">
                    Pemenuhan data NH3N dalam 24 jam: {percentageData()}%
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            {isSensorNameIncluded("debit") ? (
              <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts overflow-scroll hide-scrollbar">
                <LineChartApex
                  title="Debit"
                  selectedKey="debit"
                  label="datetime"
                  chartData={slicedData || []}
                  thresholdsValue1={thresholdsData[0]?.maxDebit || []}
                  // min={initialMinMax?.debit?.min}
                  // max={initialMinMax?.debit?.max}
                  height={186}
                  textColor="#041527"
                />
                <div className="flex justify-between pt-2">
                  <p className="m-2 -mt-8 text-xs text-slate-500">Nilai Ambang Batas: {thresholdsData[0]?.maxDebit}</p>
                  <p className="m-2 -mt-8 text-xs text-slate-500">|</p>
                  <p className="m-2 -mt-8 text-xs text-slate-500">
                    Pemenuhan data Debit dalam 24 jam: {percentageData()}%
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <p className="text-center text-xs text-slate-400 m-0 mt-4">
            Copyright © Buana Biru Teknindo
          </p>
        </>
      )}
    </>
  );
}

export default DisplayPage;
