import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useLoggerDataStore } from "../../store/loggerDataStore";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { Button, Progress } from "antd";
import LineChartApex from "../../components/LineChartApex";
import { DatePicker } from "antd";

function ChartsComponent({ loggerDataByUID, company, logger }) {
  let [searchParams, setSearchParams] = useSearchParams();
  const { RangePicker } = DatePicker;
  const { loggerDataCharts, fetchLoggerDataByUidForCharts } =
    useLoggerDataStore();
  const uidLogger = logger?.uid_logger || "";
  const startDate = searchParams.get("startDate");
  const endDate = searchParams.get("endDate");
  const [loading, setLoading] = useState(false);
  const slicedData = loggerDataCharts.slice(1, 21);
  slicedData.sort((a, b) => a.datetime - b.datetime);

  const percentageData = () => {
    const get24Time = moment()
      .subtract(1, "days")
      .subtract(2, "minutes")
      .unix();

    const filteredData = loggerDataByUID.filter(
      (data) => data.datetime >= get24Time
    );

    const percentage = (filteredData.length / 720) * 100;

    return Math.min(100, percentage).toFixed(1);
  };

  const initialMinMax = {
    cod: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    debit: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    nh3n: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    pH: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
    tss: { min: Number.MAX_VALUE, max: Number.MIN_VALUE },
  };

  if (loggerDataCharts.length > 0) {
    loggerDataCharts.forEach((item) => {
      Object.keys(initialMinMax).forEach((property) => {
        initialMinMax[property].min = Math.min(
          initialMinMax[property].min,
          item[property]
        );
        initialMinMax[property].max = Math.max(
          initialMinMax[property].max,
          item[property]
        );
      });
    });
  } else {
    // Set initialMinMax values to 0 when loggerDataCharts is empty
    Object.keys(initialMinMax).forEach((property) => {
      initialMinMax[property].min = 0;
      initialMinMax[property].max = 0;
    });
  }

  const thresholdsData = [];
  if (logger.length > 0 && logger[0]?.thresholds_logger[0]?._id) {
    thresholdsData.push(logger[0]?.thresholds_logger[0]);
  } else if (company?.id_threshold) {
    thresholdsData.push(company?.id_threshold);
  } else {
    thresholdsData.push(company?.industry_type_id?.id_threshold);
  }

  const onChange = (checkedValues) => {
    if (checkedValues) {
      let startDate = checkedValues[0].$d;
      let endDate = checkedValues[1].$d;

      // const date1 = moment(startDate).format("DD/MM/YYYY  00:00:00");
      const date1 = moment(startDate).format("DD/MM/YYYY  HH:mm:ss");
      const date2 = moment(endDate).format("DD/MM/YYYY  HH:mm:ss");
      const dateFormat = "DD/MM/YYYY HH:mm:ss";
      const timestamp1 = moment(date1, dateFormat).unix();
      const timestamp2 = moment(date2, dateFormat).unix();

      setSearchParams((params) => {
        params.set("startDate", timestamp1);
        params.set("endDate", timestamp2);
        return params;
      });
    }
  };

  const onClear = () => {
    setSearchParams((params) => {
      params.delete("startDate");
      params.delete("endDate");
      return params;
    });
  };

  useEffect(() => {
    if (logger?.uid_logger) {
      fetchLoggerDataByUidForCharts({ uidLogger, startDate, endDate });
    }
  }, [uidLogger]);

  return (
    <>
      {loading ? (
        <ClipLoader
          color={"#0C356A"}
          loading={loading}
          size={150}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      ) : (
        <>
          {/* h-full sm:h-[calc(100vh-220px)] */}

          <div className="flex flex-wrap gap-2 ">
            <RangePicker
              className=""
              onChange={onChange}
              allowClear={true}
              // showTime={{
              //   format: "HH:mm",
              // }}
              format="DD/MM/YYYY"
            />
            <Button onClick={onClear} type="primary">
              Reset Date
            </Button>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mt-4 gap-3">
            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <div className=" flex items-center justify-center gap-4 w-1/2 mx-auto py-6 rounded-md">
                <Progress
                  type="circle"
                  percent={percentageData()}
                  // strokeWidth={8}
                  // strokeColor="#ffffff"
                  // trailWidth={8}
                  // trailColor="#e0e0e0"
                />
                {percentageData() === 100 ? (
                  <p className="font-bold text-4xl m-0 mb-2 text-center text-white">
                    {percentageData()}%
                  </p>
                ) : (
                  ""
                )}
              </div>
              <p className="text-center m-0 mb-6 text-[#041527] italic">
                * Pemenuhan data dalam 24 jam
              </p>
            </div>

            <div className="bg-slate-50 border-solid border-slate-100 p-1 rounded-md shadow-charts">
              <LineChartApex
                title="pH"
                selectedKey="pH"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxpH || []}
                thresholdsValue2={thresholdsData[0]?.minpH || []}
                // min={initialMinMax?.pH?.min - 2}
                // max={initialMinMax?.pH?.max + 1}
                height="186"
                textColor="#041527"
              />
            </div>

            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="COD"
                selectedKey="cod"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxCod || []}
                // min={initialMinMax?.cod?.min}
                // max={initialMinMax?.cod?.max}
                height="186"
                textColor="#041527"
              />
            </div>

            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="TSS"
                selectedKey="tss"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxTss || []}
                // min={initialMinMax?.tss?.min}
                // max={initialMinMax?.tss?.max}
                height="186"
                textColor="#041527"
              />
            </div>

            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="Debit"
                selectedKey="debit"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxDebit || []}
                // min={initialMinMax?.debit?.min}
                // max={initialMinMax?.debit?.max}
                height="186"
                textColor="#041527"
              />
            </div>

            <div className="bg-slate-50 border-solid border-slate-100 rounded-md shadow-charts">
              <LineChartApex
                title="NH3N"
                selectedKey="nh3n"
                label="datetime"
                chartData={slicedData}
                thresholdsValue1={thresholdsData[0]?.maxNh3n || []}
                // min={initialMinMax?.nh3n?.min}
                // max={initialMinMax?.nh3n?.max}
                height="186"
                textColor="#041527"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ChartsComponent;
